import * as React from "react";
import { netQuantityData } from "types";
import { NetQuantityFormatUI } from "./netQuantityFormatUI";
interface NetQuantityFormatProps {
  data: netQuantityData;
  colorSchema: string;
}

export const NetQuantityFormat = (props: NetQuantityFormatProps) => {
  return (
    <NetQuantityFormatUI data={props.data} colorSchema={props.colorSchema} />
  );
};
