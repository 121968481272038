import * as React from "react";
import { AllergensUI } from "./AllergensUI";
import { AllergensType } from "types";
interface AllergensProps {
  data: AllergensType;
}

export const Allergens = (props: AllergensProps) => {
  return <AllergensUI data={props.data} />;
};
