import * as React from "react";
import SmaltimentoUI from "./SmaltimentoUI";
import { JsonObj } from "types";
import { FC } from "react";

interface SmaltimentoProps {
  data: JsonObj;
  language: string;
  colorSchema: string;
}

const Smaltimento: FC<SmaltimentoProps> = ({ data, language, colorSchema }) => {
  const { sustainability } = data;
  return (
    <div>
      <SmaltimentoUI
        data={sustainability}
        language={language}
        colorSchema={colorSchema}
      />
    </div>
  );
};

export default Smaltimento;
