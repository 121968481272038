import * as React from "react";
import Card from "@vapor/react-material/Card";
import CardContent from "@vapor/react-material/CardContent";
import Typography from "@vapor/react-material/CardActions";
import "./SmaltimentoStyle.scss";
import { SmaltimentoType, WasteDisposalType } from "types";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import StaticResourcesCDN, { ResourceType } from "utils/StaticResourcesCDN";
import DynamicWasteSVG from "utils/DynamicWasteSVG";

interface SmaltimentoUIProps {
  data: SmaltimentoType;
  language: string;
  colorSchema: string;
}
const SmaltimentoUI: FC<SmaltimentoUIProps> = ({
  data,
  language,
  colorSchema,
}) => {
  const { wasteDisposalComponents } = data;
  const { t } = useTranslation();

  if (!wasteDisposalComponents || wasteDisposalComponents.length === 0) {
    return <></>;
  }
  const applyColorStyles = (svgContent: string): string => {
    const colorClasses: { [key: string]: string } = {
      default: "#004e75",
      blue: "#00dcff",
      red: "#f79e1b",
      orange: "#ac9467",
      white: "#16056b",
    };
    let color = colorClasses[svgContent];
    if (colorClasses[svgContent]) {
      color = colorClasses[svgContent];
    } else {
      color = "#004e75";
    }
    return color;
  };

  return (
    <>
      <div>
        <div
          className={`dividerHeaderTitle template-sectiontitle-${colorSchema}`}
        >
          {t("sustainability")}
        </div>
        {language === "fr" && (
          <div className="wasteCountryPictogramContainer">
            <img
              src={StaticResourcesCDN(`picto-triman.svg`, ResourceType.waste)}
              alt="triman"
              className={"trimanStyle"}
            />
          </div>
        )}
        <div>
          {wasteDisposalComponents &&
            wasteDisposalComponents.map((item: WasteDisposalType) => {
              const {
                type,
                material,
                materialDetails,
                wasteDisposalInstructions,
                wasteBorderColor,
                wastePictogramCode,
                pictogramNumber,
                isPictogramVisible,
              } = item;

              return (
                <div className={"cardStyle"}>
                  <Card
                    style={{
                      borderRadius: "20px",
                      border: `3px solid #${wasteBorderColor}`,
                      boxShadow: "none",
                      minWidth: "200px",
                    }}
                    sx={{ minWidth: 275 }}
                    elevation={0}
                  >
                    <CardContent
                      className={`cardContentStyle template-background-${colorSchema}`}
                    >
                      <div style={{ width: "90%" }}>
                        <Typography
                          className={"typoGraphyStyle"}
                          sx={{ fontSize: 14 }}
                        >
                          <div
                            className={`titleWasteDisposal template-sectionsubtitle-${colorSchema}`}
                          >
                            {type}
                          </div>
                        </Typography>
                        <Typography
                          className={`typoGraphyStyle template-waste-type-${colorSchema}`}
                          style={{ fontWeight: 700 }}
                        >
                          {material}
                        </Typography>
                        <Typography
                          className={`typoGraphyStyle template-text-${colorSchema}`}
                          sx={{ mb: 1.5 }}
                        >
                          {materialDetails}
                        </Typography>
                        <Typography
                          className={`typoGraphyStyle template-text-${colorSchema}`}
                        >
                          {wasteDisposalInstructions}
                        </Typography>
                      </div>

                      <div>
                        {isPictogramVisible && (
                          <div style={{ width: "8%", height: "8%" }}>
                            <DynamicWasteSVG
                              dynamicValues={{
                                number: pictogramNumber,
                                code: wastePictogramCode,
                              }}
                              color={applyColorStyles(colorSchema)}
                            />
                          </div>
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
          <div
            className={`mainBlock template-messages-${colorSchema}`}
            style={{ textAlign: "justify", fontWeight: 400 }}
          >
            {t("disposalWarningMessage")}
          </div>
        </div>
      </div>
      <div className={`dividerStyle`}>
        <hr className={`template-horiz-separator-${colorSchema}`} />
      </div>
    </>
  );
};

export default SmaltimentoUI;
