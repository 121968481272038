import * as React from "react";
import "./Imbottigliato.scss";
import { OperatorType } from "types";
import isNullOrEmpty from "utils/stringHelper";
import { useTranslation } from "react-i18next";

interface ImbottigliatoUIProps {
  data: OperatorType;
  colorSchema: string;
  isImporter: boolean;
}

export const ImbottigliatoUI = (props: ImbottigliatoUIProps) => {
  const { data, colorSchema, isImporter } = props;
  const { t } = useTranslation();

  if (
    isNullOrEmpty(data.name) &&
    isNullOrEmpty(data.city) &&
    isNullOrEmpty(data.country) &&
    isNullOrEmpty(data.address)
  )
    return <></>;

  return (
    <>
      <div className="generalDiv imbText ">
        <b
          style={{ marginRight: "5px" }}
          className={`template-sectiontitle-${colorSchema}`}
        >
          {isImporter
            ? t("importedBy").toUpperCase()
            : data.uiLabel.toUpperCase()}
        </b>{" "}
        <span className={`template-subtitle-${colorSchema}`}>
          {" "}
          {data && data.name}
        </span>
      </div>
      {data && (
        <div
          className={`generalDiv imbText template-subtitle-${colorSchema}`}
        >{`${data.city} ${data.province ? "-" + data.province : ""}`}</div>
      )}
      <div className={`dividerStyle`}>
        <hr className={`template-horiz-separator-${colorSchema}`} />
      </div>
    </>
  );
};
