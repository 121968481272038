import * as React from "react";
import AziendaUI from "./AziendaUI";
import { AziendaType } from "types";
import { FC } from "react";

interface AziendaProps {
  data: AziendaType;
  colorSchema: string;
}

const Azienda: FC<AziendaProps> = ({ data, colorSchema }) => {
  return (
    <div style={{ marginBottom: 40 }}>
      <AziendaUI data={data} colorSchema={colorSchema} />
    </div>
  );
};

export default Azienda;
