import * as React from "react";
import * as ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./styles.scss"; // Import the Sass file
import "./i18n";

const rootNode: HTMLElement = document.getElementById("root") as HTMLElement;
ReactDOM.render(<App />, rootNode);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
