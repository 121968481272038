const areAllPropertiesEmpty = (obj: any) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] !== null && obj[key] !== undefined) {
        if (typeof obj[key] === "string" && obj[key].trim() !== "") {
          return false;
        } else if (Array.isArray(obj[key]) && obj[key].length !== 0) {
          return false;
        } else if (
          typeof obj[key] === "object" &&
          Object.keys(obj[key]).length > 0
        ) {
          return false;
        }
      }
    }
  }
  return true; // All properties are empty
};

export default areAllPropertiesEmpty;
