import * as React from "react";
import { GeneralInfoUI } from "./GeneralInfoUI";
import { ProductType, CharacteristicsType, generic } from "types";

interface GeneralInfoProps {
  data: ProductType;
  prodFeatures: CharacteristicsType;
  language: generic;
  companyName: string;
  colorSchema: string;
}

export const GeneralInfo = (props: GeneralInfoProps) => {
  return <GeneralInfoUI {...props} />;
};
