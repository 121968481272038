import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import enTranslations from "./locales/en.json";
import itTranslations from "./locales/it.json";
import frTranslations from "./locales/fr.json";
import mtTranslations from "./locales/en.json"; // maltese
import bgTranslations from "./locales/bg.json";
import deTranslations from "./locales/de.json";
import esTranslations from "./locales/es.json";
import nlTranslations from "./locales/nl.json";
import plTranslations from "./locales/pl.json";
import ptTranslations from "./locales/pt.json";
import roTranslations from "./locales/ro.json";
import csTranslations from "./locales/cs.json";
import daTranslations from "./locales/da.json";
import elTranslations from "./locales/el.json";
import etTranslations from "./locales/et.json";
import fiTranslations from "./locales/fi.json";
import gaTranslations from "./locales/ga.json";
import hrTranslations from "./locales/hr.json";
import huTranslations from "./locales/hu.json";
import ltTranslations from "./locales/lt.json";
import lvTranslations from "./locales/lv.json";
import skTranslations from "./locales/sk.json";
import slTranslations from "./locales/sl.json";
import svTranslations from "./locales/sv.json";

import bgListLangs from "./locales/langList.bg.json";
import csListLangs from "./locales/langList.cs.json";

import itListLangs from "./locales/langList.it.json";
import enListLangs from "./locales/langList.en.json";
import frListLangs from "./locales/langList.fr.json";
import mtListLangs from "./locales/langList.mt.json";
import deListLangs from "./locales/langList.de.json";
import esListLangs from "./locales/langList.es.json";
import nlListLangs from "./locales/langList.nl.json";
import plListLangs from "./locales/langList.pl.json";
import ptListLangs from "./locales/langList.pt.json";
import roListLangs from "./locales/langList.ro.json";
import daListLangs from "./locales/langList.da.json";
import elListLangs from "./locales/langList.el.json";
import etListLangs from "./locales/langList.et.json";
import fiListLangs from "./locales/langList.fi.json";
import gaListLangs from "./locales/langList.ga.json";
import hrListLangs from "./locales/langList.hr.json";
import huListLangs from "./locales/langList.hu.json";
import ltListLangs from "./locales/langList.lt.json";
import lvListLangs from "./locales/langList.lv.json";
import skListLangs from "./locales/langList.sk.json";
import slListLangs from "./locales/langList.sl.json";
import svListLangs from "./locales/langList.sv.json";
import { LangElement } from "components/prodotto/header/LangElement.interface";

const getLangsList = (lang: string): Array<LangElement> => {
  switch (lang) {
    case 'en':
      return enListLangs;
    case 'it':
      return itListLangs;
    case 'fr':
      return frListLangs;
    case 'mt':
      return mtListLangs;
    case 'bg':
      return bgListLangs;
    case 'cs':
      return csListLangs;
    case 'de':
      return deListLangs;
    case 'es':
      return esListLangs;
    case 'nl':
      return nlListLangs;
    case 'pl':
      return plListLangs;
    case 'pt':
      return ptListLangs;
    case 'ro':
      return roListLangs;
    case 'da':
      return daListLangs;
    case 'el':
      return elListLangs;
    case 'et':
      return etListLangs;
    case 'fi':
      return fiListLangs;
    case 'ga':
      return gaListLangs;
    case 'hr':
      return hrListLangs;
    case 'hu':
      return huListLangs;
    case 'lt':
      return ltListLangs;
    case 'lv':
      return lvListLangs;
    case 'sk':
      return skListLangs;
    case 'sl':
      return slListLangs;
    case 'sv':
      return svListLangs;
    default:
      return enListLangs;
  }
}


const foreignStandardLng: string = 'en';
const standardLng: string = 'it';

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      it: { translation: itTranslations },
      fr: { translation: frTranslations },
      mt: { translation: mtTranslations },
      bg: { translation: bgTranslations },
      de: { translation: deTranslations },
      es: { translation: esTranslations },
      nl: { translation: nlTranslations },
      pl: { translation: plTranslations },
      pt: { translation: ptTranslations },
      ro: { translation: roTranslations },
      cs: { translation: csTranslations },
      da: { translation: daTranslations },
      el: { translation: elTranslations },
      et: { translation: etTranslations },
      fi: { translation: fiTranslations },
      ga: { translation: gaTranslations },
      hr: { translation: hrTranslations },
      hu: { translation: huTranslations },
      lt: { translation: ltTranslations },
      lv: { translation: lvTranslations },
      sk: { translation: skTranslations },
      sl: { translation: slTranslations },
      sv: { translation: svTranslations },      
    },
    fallbackLng: foreignStandardLng,
    interpolation: {
      escapeValue: false,
    },
  });


export { i18n, getLangsList, foreignStandardLng, standardLng };
