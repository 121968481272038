import * as React from "react";
import "./Ingredients.scss";
import { IngredientsType } from "types";
import { useTranslation } from "react-i18next";
interface IngredientsUIProps {
  data: IngredientsType;
  packagingGassesMessage: { message: string };
  colorSchema: string;
}

export const IngredientsUI = (props: IngredientsUIProps) => {
  const { data, packagingGassesMessage, colorSchema } = props;
  const { t } = useTranslation();

  const replacePlaceholders = (
    text: string,
    ingredients: { name: string; isBold: boolean; order: number }[],
    pattern: string = ",",
    separator: string
  ) => {
    const replacedText = pattern.replace(/\$groupName/g, text);

    const jsxElements: JSX.Element[] = [];
    replacedText.split(/\$ingredients/g).forEach((part, index, array) => {
      jsxElements.push(<span key={`part-${index}`}>{part}</span>);
      if (index < array.length - 1 && ingredients) {
        jsxElements.push(
          <React.Fragment key={`ingredients-${index}`}>
            {ingredients.map((ingredient, i) => (
              <React.Fragment key={`ingredient-${i}`}>
                {ingredient.isBold ? (
                  <strong key={`bold-${i}`}>{ingredient.name}</strong>
                ) : (
                  <span key={`normal-${i}`}>{ingredient.name}</span>
                )}
                {i < ingredients.length - 1 && (
                  <span key={`separator-${i}`}>{getSeparator(separator)}</span>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        );
      }
    });

    return <>{jsxElements}</>;
  };

  const getSeparator = (separator: string) => {
    return /[,.;]/.test(separator) ? `${separator} ` : ` ${separator} `;
  };
  const sortedData = data.sort((a, b) => a.order - b.order);

  const groupedData: {
    [group: string]: {
      order: number;
      groupName: string;
      ingredients: { name: string; isBold: boolean; order: number }[];
      separator: string;
      pattern: string;
      label: JSX.Element;
    }[];
  } = sortedData.reduce((acc: any, item) => {
    if (!acc[item.groupName]) {
      acc[item.groupName] = [];
    }

    acc[item.groupName].push({
      label: (
        <span key={item.groupName}>
          {replacePlaceholders(
            item.groupName,
            item.ingredients,
            item.pattern,
            item.separator
          )}
        </span>
      ),
      groupName: item.groupName,
      ingredients: item.ingredients.map((child: any) => ({
        name: child.name,
        isBold: child.isBold,
        order: child.order,
      })),
      separator: item.separator || ", ",
      pattern: item.pattern,
      order: item.order,
    });

    return acc;
  }, {});

  return (
    <div>
      <div key={"ingredienti"} className="generalDiv">
        <div
          key={"consumoResp"}
          className={`sectionTitle template-sectiontitle-${colorSchema}`}
        >
          {t("ingredients")}
        </div>
      </div>
      <div key={"ingredientiDesc_list"} className="generalDiv">
        <div
          key={"consumoRespDesc"}
          className={`template-sectiontext-${colorSchema}`}
        >
          {groupedData &&
            Object.entries(groupedData)
              .sort(([groupA, labelsA], [groupB, labelsB]) => {
                return labelsA[0].order - labelsB[0].order;
              })
              .map(([group, labels], index, array) => (
                <span key={index}>
                  {group && group !== "null" && (
                    <span>
                      {labels &&
                        labels
                          .sort((a, b) => a.order - b.order)
                          .map((labelItem, labelIndex) => (
                            <span key={labelIndex}>
                              {labelItem.label}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))}
                    </span>
                  )}
                </span>
              ))}
        </div>
      </div>

      {packagingGassesMessage && packagingGassesMessage.message && (
        <div
          key={"ingredientiDesc"}
          className="generalDiv"
          style={{ marginTop: "24px" }}
        >
          <div
            key={"consumoRespDesc"}
            className={`gasMessage template-messages-${colorSchema}`}
          >
            {packagingGassesMessage.message}
          </div>
        </div>
      )}
    </div>
  );
};
