import * as React from "react";
import { CustomDetails } from "../customDetails/CustomDetails";
import "../ProdottoMain.scss";
import "./ProductCharacteristics.scss";
import { CharacteristicsType } from "types";
import { t } from "i18next";
import "../../../ts-fonts.css";

interface ProductCharacteristicsUIProps {
  data: CharacteristicsType;
  colorSchema: string;
}

export const ProductCharacteristicsUI = (
  props: ProductCharacteristicsUIProps
) => {
  const { data, colorSchema } = props;

  return (
    <div className="prodChar">
      <div key={"caratterProd"} className="generalDiv">
        <div
          key={"caratterProdText"}
          className={`sectionTitle template-sectiontitle-${colorSchema}`}
        >
          {t("characteristic")}
        </div>
      </div>
      <div key={"caratterProd_cell"} className="generalDiv">
        <div className="charTable">
          {data && data.harvestyear && (
            <div
              className={`charTableCell template-vert-separator-${colorSchema}`}
            >
              {" "}
              <CustomDetails
                title={t("harvestYearLabel")}
                description={data && data.harvestyear ? data.harvestyear : ""}
                icon={<span className="ts-icon-annata iconProdStyle" />}
                colorSchema={colorSchema}
              />{" "}
            </div>
          )}

          {data && data.servingtemperature && (
            <div
              className={`charTableCell template-vert-separator-${colorSchema}`}
            >
              <CustomDetails
                title={t("servingtemperatureLabel")}
                description={
                  data && data.servingtemperature ? data.servingtemperature : ""
                }
                icon={
                  <span className="ts-icon-temperaturaservizio iconProdStyle" />
                }
                colorSchema={colorSchema}
              />
            </div>
          )}

          {data && data.alcoholicstrengthpercvolume && (
            <div
              className={`charTableCell template-vert-separator-${colorSchema}`}
            >
              <CustomDetails
                title={t("alcoholicstrengthpercvolumeLabel")}
                description={
                  data && data.alcoholicstrengthpercvolume
                    ? `${data.alcoholicstrengthpercvolume}% vol.`
                    : ""
                }
                icon={<span className="ts-icon-gradalcolica iconProdStyle" />}
                colorSchema={colorSchema}
              />
            </div>
          )}

          {data && data.varieties && (
            <div
              className={`charTableCell template-vert-separator-${colorSchema}`}
            >
              <CustomDetails
                title={t("varietiesLabel")}
                description={data && data.varieties ? data.varieties : ""}
                icon={<span className="ts-icon-vitigni iconProdStyle" />}
                colorSchema={colorSchema}
              />
            </div>
          )}

          {data && data.productionmethodsterms && (
            <div
              className={`charTableCell template-vert-separator-${colorSchema}`}
            >
              <CustomDetails
                title={t("productionmethodstermsLabel")}
                description={
                  data && data.productionmethodsterms
                    ? data.productionmethodsterms
                    : ""
                }
                icon={<span className="ts-icon-affinamento iconProdStyle" />}
                colorSchema={colorSchema}
              />
            </div>
          )}
          {data && data.holdingterms && (
            <div
              className={`charTableCell template-vert-separator-${colorSchema}`}
            >
              <CustomDetails
                title={t("holdingtermsLabel")}
                description={data && data.holdingterms ? data.holdingterms : ""}
                icon={
                  <span className="ts-icon-tempoaffinamento iconProdStyle" />
                }
                colorSchema={colorSchema}
              />
            </div>
          )}
        </div>
      </div>
      {data && data.growingarea && (
        <CustomDetails
          title={t("growingAreaLabel")}
          description={data.growingarea}
          icon={null}
          colorSchema={colorSchema}
        />
      )}
      {data && data.description && (
        <CustomDetails
          title={t("description")}
          description={data.description}
          icon={null}
          colorSchema={colorSchema}
        />
      )}
      {data &&
        data.variablesCharacteristic &&
        data.variablesCharacteristic.map((item) => {
          return (
            <CustomDetails
              title={item.label}
              description={item.description}
              icon={null}
              colorSchema={colorSchema}
            />
          );
        })}

      {data &&
        data.custimCharacteristic &&
        data.custimCharacteristic.length > 0 &&
        data.custimCharacteristic.map((characteristic, index) => {
          return (
            <div key={index} className="charateristicDiv">
              <div className={"secondaryContainerNoIcon"}>
                <div className={`titleDiv template-icon-${colorSchema}`}>
                  {characteristic.label}
                </div>
                <div className={`descDiv template-icon-text-${colorSchema}`}>
                  {characteristic.description}
                </div>
              </div>
              {characteristic.imageUrl && (
                <img
                  src={characteristic.imageUrl}
                  alt="icon"
                  className="mainImage"
                />
              )}
            </div>
          );
        })}
    </div>
  );
};
