import * as React from "react";
import { CustomDetails } from "../customDetails/CustomDetails";
import { TastingType } from "types";
import { t } from "i18next";
import isNullOrEmpty from "utils/stringHelper";

interface DegustazioneUIProps {
  data: TastingType;
  colorSchema: string;
}

export const DegustazioneUI = (props: DegustazioneUIProps) => {
  const { data, colorSchema } = props;

  if (
    isNullOrEmpty(data.colour) &&
    isNullOrEmpty(data.aromas) &&
    isNullOrEmpty(data.taste) &&
    isNullOrEmpty(data.winePairing) &&
    data.customTasting &&
    data.customTasting.result &&
    data.customTasting.result.length === 0
  ) {
    return <></>;
  }

  return (
    <>
      <div>
        <div key={"noteDeg"} className="generalDiv">
          <div
            key={"noteDegText"}
            className={`sectionTitle template-sectiontitle-${colorSchema}`}
          >
            {t("tasting")}
          </div>
        </div>
        {data && data.colour && (
          <CustomDetails
            title={t("colour")}
            description={data && data.colour}
            icon={null}
            colorSchema={colorSchema}
          />
        )}
        {data && data.aromas && (
          <CustomDetails
            title={t("aromas")}
            description={data && data.aromas}
            icon={null}
            colorSchema={colorSchema}
          />
        )}
        {data && data.taste && (
          <CustomDetails
            title={t("taste")}
            description={data && data.taste}
            icon={null}
            colorSchema={colorSchema}
          />
        )}
        {data && data.winePairing && (
          <CustomDetails
            title={t("winePairing")}
            description={data && data.winePairing}
            icon={null}
            colorSchema={colorSchema}
          />
        )}
        {data &&
          data.customTasting &&
          data.customTasting.result &&
          data.customTasting.result.length > 0 &&
          data.customTasting.result.map((field, index) => {
            return (
              <CustomDetails
                title={field.title}
                description={field.description}
                icon={null}
                key={index}
                colorSchema={colorSchema}
              />
            );
          })}
      </div>
      <div className={`dividerStyle`}>
        <hr className={`template-horiz-separator-${colorSchema}`} />
      </div>
    </>
  );
};
