import * as React from "react";
import { netQuantityData } from "types";
import { useTranslation } from "react-i18next";

interface NetQuantityFormatUIProps {
  data: netQuantityData;
  colorSchema: string;
}

export const NetQuantityFormatUI = (props: NetQuantityFormatUIProps) => {
  const { data, colorSchema } = props;
  const { t } = useTranslation();

  return (
    <>
      {data && data.length > 0 && <><div
        className="generalDiv imbText"
        style={{
          display: "grid",
          gridColumnGap: "10px",
          alignItems: "center",
        }}
      >
        <div
          key={"consumoResp"}
          className={`sectionTitle template-sectiontitle-${colorSchema}`}
          style={{ gridColumn: "1 / span 2", textAlign: "center" }}
        >
          {t("format")}
        </div>
        {data.map((e, index) => (
          <React.Fragment key={`consumoResp-${index}`}>
            <div
              className={`template-subtitle-${colorSchema}`}
              style={{ textAlign: "right" }}
            >
              {e.netQuantity}
            </div>
            <div
              className={`template-subtitle-${colorSchema}`}
              style={{ textAlign: "left" }}
            >
              {e.netQuantityFormat}
            </div>
          </React.Fragment>
        ))}
      </div>
        <div className={`dividerStyle`}>
          <hr className={`template-horiz-separator-${colorSchema}`} />
        </div></>}
    </>
  );
};
