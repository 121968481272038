export enum ResourceType {
  waste = "staticwaste",
  responsibleConsumption = "staticrespcons",
  pdopgi = "staticpdopgi",
  countries = "staticcountries",
  brands = "staticbrands",
  allergens = "staticallergens",
  organic = "staticorganiclogos",
}

const StaticResourcesCDN = (imageName: string, type: ResourceType) => {
  if (!imageName) return "";
  try {
    const staticFileUrl = process.env.REACT_APP_STATIC_FILES_URL;

    return `${staticFileUrl}/${type}/${imageName}`;
  } catch (err) {
    console.log(err);
  }
  return "";
};

export default StaticResourcesCDN;
