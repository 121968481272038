import * as React from "react";
import "./AziendaStyle.scss";
import { AziendaType } from "types";
import { FC, useState } from "react";
import { t } from "i18next";
import { FaGlobe, FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Stack,
} from "@vapor/react-material";

interface AziendaUIProps {
  data: AziendaType;
  colorSchema: string;
}

const AziendaUI: FC<AziendaUIProps> = ({ data, colorSchema }) => {
  const xtwitter = require(`../../../images/brands/x-twitter.svg`).default; // this is temporary because the last version of react react-fontawesome doesn't cover this brand yet

  const {
    websiteLink,
    facebookLink,
    twitterLink,
    instagramLink,
    linkedinLink,
    description,
    logo,
    name,
  } = data ?? {};
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState<string>();

  const openPopup = (item: string) => {
    setPopupOpen(true);
    setSelectedIcon(item);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const confirmOpen = () => {
    selectedIcon && window.open(selectedIcon, "_blank");

    closePopup();
  };
  return (
    <div>
      <div
        className={`dividerHeaderTitle template-sectiontitle-${colorSchema}`}
      >
        {t("company")}
      </div>
      <div>
        {logo && (
          <div className={"imageDivStyle"}>
            <img
              src={logo}
              className={"aziendaImageStyle"}
              alt="Azienda Brand Image"
            />
          </div>
        )}
        <div
          style={{
            textAlign: "center",
            fontWeight: "700",
            margin: "3% 0%",
          }}
          className={`template-company-title-${colorSchema}`}
        >
          {name}
        </div>
        <div
          className={`mainBlock textStyle template-sectiontext-${colorSchema}`}
        >
          {description}
          <div className={"iconDivStyle"}>
            {!!websiteLink ? (
              <FaGlobe
                size={32}
                style={{ color: "#3963a4", cursor: "pointer" }}
                onClick={() => openPopup(websiteLink)}
                title="Company Website"
                className={`template-socialbutton-${colorSchema}`}
              />
            ) : null}
            {!!facebookLink ? (
              <FaFacebook
                size={32}
                style={{ color: "#3b5998", cursor: "pointer" }}
                onClick={() => openPopup(facebookLink)}
                title="Facebook"
                className={`template-socialbutton-${colorSchema}`}
              />
            ) : null}
            {!!linkedinLink ? (
              <FaLinkedin
                size={32}
                style={{ color: "#0e76a8", cursor: "pointer" }}
                onClick={() => openPopup(linkedinLink)}
                title="Linkedin"
                className={`template-socialbutton-${colorSchema}`}
              />
            ) : null}
            {!!twitterLink ? (
              // <FaTwitter
              //   size={32}
              //   style={{ color: "#1da1f2", cursor: "pointer" }}
              //   onClick={() => window.open(twitterLink, "_blank")}
              // />
              <img
                src={xtwitter}
                alt="twitter"
                width={28}
                onClick={() => openPopup(twitterLink)}
                style={{ cursor: "pointer" }}
                title="Twitter"
                className={`template-socialbutton-${colorSchema}`}
              />
            ) : null}
            {!!instagramLink ? (
              <FaInstagram
                size={32}
                style={{ color: "#e4405f", cursor: "pointer" }}
                onClick={() => openPopup(instagramLink)}
                title="Instagram"
                className={`template-socialbutton-${colorSchema}`}
              />
            ) : null}
          </div>
          <Dialog open={popupOpen} onClose={closePopup}>
            <DialogTitle alignSelf="center">
              {t("externalWarnTitle")}
            </DialogTitle>

            <div className="messageDescription">
              {t("externalWarnBodyText")}
            </div>

            <DialogActions
              sx={{
                justifyContent: "center",
                width: "93%",
                display: "block",
              }}
            >
              {" "}
              <Stack spacing={2}>
                <Button
                  onClick={confirmOpen}
                  variant="outlined"
                  color="primary"
                >
                  {t("externalWarnOkBtn")}
                </Button>
                <Button onClick={closePopup} variant="outlined" color="error">
                  {t("externalWarnCancelBtn")}
                </Button>
              </Stack>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default AziendaUI;
