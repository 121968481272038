import * as React from "react";
import { Select, MenuItem } from "@vapor/react-material";
import "./Header.scss";
import AppBar from "@mui/material/AppBar";
import { LangElement } from "./LangElement.interface";

import "../../../AppTemplates.scss";

interface HeaderUIProps {
  setLanguage: (langauge: string) => void;
  languages: Array<LangElement>;
  language: string;
  colorSchema: string;
}
const logo: string = require("./tslogo.svg").default;
const euFlag: string = require("./eu_flag.svg").default;

export const HeaderUI = (props: HeaderUIProps) => {
  const { languages, language, setLanguage, colorSchema } = props;
  const customFontFamily = {
    fontFamily: "Roboto, sans-serif", // Specify 'Roboto' as the font family
  };
  return (
    <AppBar elevation={0} position="fixed" sx={{ bottom: "auto", top: 0 }}>
      <div className={`header template-background-${colorSchema}`}>
        <div key={"header-left"} className="header-left Title">
          <img src={logo} alt="Teamsytem logo" />
          <span className={`brand-title template-logo-title-${colorSchema}`}>
            E-Label
          </span>
        </div>
        <div key={"header-right"} className="header-right">
          <img className="euHeaderFlag" src={euFlag} alt="European flag" />
          <Select
            datatype=""
            className="languageInput"
            value={language}
            onChange={(e: any) => {
              setLanguage(e.target.value);
            }}
            style={customFontFamily}
            inputProps={{
              style: customFontFamily,
            }}
          >
            {languages &&
              languages.map((lng) => {
                return (
                  <MenuItem value={lng.name} style={customFontFamily}>
                    {lng.lang}
                  </MenuItem>
                );
              })}
          </Select>
        </div>
      </div>
    </AppBar>
  );
};
