import * as React from "react";
import { IngredientsUI } from "./IngredientsUI";
import { IngredientsType } from "types";
interface IngredientsProps {
  data: IngredientsType;
  packagingGassesMessage: { message: string };
  colorSchema: string;
}

export const Ingredients = (props: IngredientsProps) => {
  return (
    <IngredientsUI
      data={props.data}
      packagingGassesMessage={props.packagingGassesMessage}
      colorSchema={props.colorSchema}
    />
  );
};
