import React, { useEffect, useRef, useState } from "react";

interface SVGProps {
  dynamicValues:
    | { number: number | undefined; code: string | undefined }
    | undefined;
  color: string;
}

const DynamicWasteSVG: React.FC<SVGProps> = ({ dynamicValues, color }) => {
  const [textWidth, setTextWidth] = useState(0);
  const tspanRef = useRef<SVGTSpanElement>(null);

  useEffect(() => {
    if (tspanRef.current) {
      const bbox = tspanRef.current.getBBox();
      setTextWidth(bbox.width);
    }
  }, [dynamicValues]);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="75"
      height="75"
      id="svg2"
    >
      <g style={{ display: "inline" }} id="layer1">
        <path
          d="M 23.7223995,23.623515 L 33.587823,7.1811445 C 33.587823,7.1811445 37.55156625,3.339175775 41.024205,6.812814525 L 50.2143585,22.3957515"
          style={{
            fill: "none",
            fillOpacity: 0.75,
            fillRule: "evenodd",
            stroke: color,
            strokeWidth: 5.25,
            strokeLinecap: "butt",
            strokeLinejoin: "round",
            markerStart: "none",
            markerMid: "none",
            markerEnd: "none",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          id="path1390"
        />
        <text
          x="28.6544295"
          y="41.25"
          style={{
            fontSize: "22px",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: "bold",
            fontStretch: "normal",
            textAlign: "start",
            lineHeight: "125%",
            textAnchor: "start",
            fill: color,
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: "1px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
            fontFamily: "Nimbus Sans L",
          }}
          id="text2465"
          xmlSpace="preserve"
        >
          <tspan
            x={
              dynamicValues &&
              dynamicValues.number &&
              (75 - dynamicValues.number.toString().length * 7.5) / 2
            }
            y="41.25"
            style={{
              fontSize: "16.5px",
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: "bold",
              fontStretch: "normal",
              textAlign: "start",
              lineHeight: "125%",
              textAnchor: "start",
              fontFamily: "Nimbus Sans L",
            }}
            id="tspan2467"
          >
            {dynamicValues?.number ? dynamicValues?.number : ""}
          </tspan>
        </text>
        <text
          x={(75 - textWidth) / 2}
          y="71.77650225"
          style={{
            fontSize: "16.5px",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: "bold",
            fontStretch: "normal",
            textAlign: "start",
            lineHeight: "125%",
            textAnchor: "start",
            fill: color,
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: "1px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
            fontFamily: "Nimbus Sans L",
          }}
          id="text2469"
          xmlSpace="preserve"
        >
          <tspan
            x={(75 - textWidth) / 2}
            y="71.77650225"
            style={{
              fontSize: "16.5px",
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: "bold",
              fontStretch: "normal",
              textAlign: "start",
              lineHeight: "125%",
              textAnchor: "start",
              fontFamily: "Nimbus Sans L",
            }}
            id="tspan2471"
            ref={tspanRef}
          >
            {dynamicValues && dynamicValues.code}
          </tspan>
        </text>
        <path
          d="M 34.4593965,52.5 L 15.2871735,52.177479 C 15.2871735,52.177479 9.97867425,50.67726 11.25,45.9215475 L 20.15014275,30.17117225"
          style={{
            fill: "none",
            fillOpacity: 0.75,
            fillRule: "evenodd",
            stroke: color,
            strokeWidth: 5.25,
            strokeLinecap: "butt",
            strokeLinejoin: "round",
            markerStart: "none",
            markerMid: "none",
            markerEnd: "none",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          id="path3262"
        />
        <path
          d="M 54.09866925,28.7632455 L 63.40546725,45.528138 C 63.40546725,45.528138 64.74997125,50.8813245 60.00625875,52.152599 L 41.9159625,52.3198365"
          style={{
            fill: "none",
            fillOpacity: 0.75,
            fillRule: "evenodd",
            stroke: color,
            strokeWidth: 5.25,
            strokeLinecap: "butt",
            strokeLinejoin: "round",
            markerStart: "none",
            markerMid: "none",
            markerEnd: "none",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          id="path3264"
        />
        <path
          d="M 34.5395745,52.367159 L 45.54299875,45.8862795 L 45.54299875,58.6470825 L 34.5395745,52.367159 z "
          style={{ fill: color }}
          id="head2"
        />
        <path
          d="M 12.94061325,30.19980375 L 23.9430375,23.718924 L 23.9430375,36.479727 L 12.94061325,30.19980375 z "
          style={{ fill: color }}
          id="head3"
        />
        <path
          d="M 42.9589065,22.490964 L 53.96133075,16.0100845 L 53.96133075,28.7708875 L 42.9589065,22.490964 z "
          style={{ fill: color }}
          id="head1"
        />
      </g>
    </svg>
  );
};

export default DynamicWasteSVG;
