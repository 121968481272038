import * as React from "react";
import "./ProdottoMain.scss";
import { GeneralInfo } from "./generalInfo/GeneralInfo";
import { Imbottigliato } from "./imbottigliato/Imbottigliato";
import { Ingredients } from "./ingredients/Ingredients";
import { Allergens } from "./ingredients/Allergens";
import { JsonObj } from "types";
import { NetQuantityFormat } from "./netQuantity/netQuantityFormat";
import areAllPropertiesEmpty from "utils/PropertiesEmpty";

interface ProdottoMainUIProps {
  data: JsonObj;
  colorSchema: string;
}

const ProdottoMainUI = (props: ProdottoMainUIProps) => {
  const { data, colorSchema } = props;

  return (
    <div className="mainProdottoDiv">
      <GeneralInfo
        data={data.product}
        prodFeatures={data.characteristic}
        companyName={data.company.name}
        language={data.language}
        colorSchema={colorSchema}
      />
      <div className={`dividerStyle`}>
        <hr className={`template-horiz-separator-${colorSchema}`} />
      </div>
      {!areAllPropertiesEmpty(data.netQuantityData) && (
        <NetQuantityFormat
          data={data.netQuantityData}
          colorSchema={colorSchema}
        />
      )}
      {!areAllPropertiesEmpty(data.foodBusinessOperator) && (
        <Imbottigliato
          data={data.foodBusinessOperator}
          colorSchema={colorSchema}
          isImporter={false}
        />
      )}
      {!areAllPropertiesEmpty(data.foodBusinessOperatorImporter) && (
        <Imbottigliato
          data={data.foodBusinessOperatorImporter}
          colorSchema={colorSchema}
          isImporter={true}
        />
      )}

      {!data.product.hiddenIngredientsSections && (
        <>
          {!areAllPropertiesEmpty(data.ingredientData) && (
            <Ingredients
              data={data.ingredientData}
              packagingGassesMessage={data.packagingGassesMessage}
              colorSchema={colorSchema}
            />
          )}
          {!areAllPropertiesEmpty(data.allergenPictograms) && (
            <>
              <Allergens data={data.allergenPictograms} />
              <div className={`dividerStyle`}>
                <hr className={`template-horiz-separator-${colorSchema}`} />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProdottoMainUI;
