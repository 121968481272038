import * as React from "react";
import AllegatiDaScaricareUI from "./AllegatiDaScaricareUI";
import { AttachmentsType } from "types";
import { FC } from "react";

interface AllegatiDaScaricareProps {
  data: AttachmentsType;
  colorSchema: string;
}

const AllegatiDaScaricare: FC<AllegatiDaScaricareProps> = ({
  data,
  colorSchema,
}) => {
  const { attachmentsList, sectionLabel } = data;
  const handleDownload = (name: string, link: string) => {
    //window.open(link, "_blank");
    // fetch(link)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     saveAs(blob, `${name}.pdf`);
    //   })
    //   .catch((error) => {
    //     console.error("Error occurred while downloading the PDF:", error);
    //   });
  };
  return (
    <div>
      <AllegatiDaScaricareUI
        sectionLabel={sectionLabel}
        attachmentsList={attachmentsList}
        handleDownload={handleDownload}
        colorSchema={colorSchema}
      />
    </div>
  );
};

export default AllegatiDaScaricare;
