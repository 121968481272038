import * as React from "react";
import ValoriNutrizionaliUI from "./ValoriNutrizionaliUI";
import { JsonObj } from "types";
import { FC } from "react";

interface ValoriNutrizionaliProps {
  data: JsonObj;
  colorSchema: string;
}

const ValoriNutrizionali: FC<ValoriNutrizionaliProps> = ({
  data,
  colorSchema,
}) => {
  return (
    <div>
      <ValoriNutrizionaliUI colorSchema={colorSchema} data={data} />
    </div>
  );
};

export default ValoriNutrizionali;
