import * as React from "react";
import { ResponsabilityUI } from "./ResponsabilityUI";
import { ResponsabilityType } from "types";
interface ResponsabilityProps {
  data: ResponsabilityType;
  colorSchema: string;
}

export const Responsability = (props: ResponsabilityProps) => {
  return <ResponsabilityUI data={props.data} colorSchema={props.colorSchema} />;
};
