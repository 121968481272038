import * as React from "react";
import "./Responsability.scss";
import { ResponsabilityType } from "types";
import { useTranslation } from "react-i18next";
import StaticResourcesCDN, { ResourceType } from "utils/StaticResourcesCDN";
interface ResponsabilityUIProps {
  data: ResponsabilityType;
  colorSchema: string;
}

export const ResponsabilityUI = (props: ResponsabilityUIProps) => {
  const { data, colorSchema } = props;
  const { t } = useTranslation();
  return (
    <div>
      <div key={"consumoResp"} className="generalDiv">
        <div
          key={"consumoResp"}
          className={`consumoResp template-sectiontitle-${colorSchema}`}
        >
          {t("responsibleConsumption")}
        </div>
      </div>
      <div key={"consumoRespIcons"} className="generalDiv responsibleConsumptionContainer">
        {data &&
          data.pictograms &&
          data.pictograms.map((iconName: string) => {
            return (
              <div
                key={`consumoRespIcons_${iconName}`}
                className="consumoRespSquareIconWide"
              >
                <img
                  className="consumoRespIcon"
                  src={StaticResourcesCDN(
                    `${iconName}.png`,
                    ResourceType.responsibleConsumption
                  )}
                  alt={iconName}
                  style={{ backgroundColor: "white" }}
                />
              </div>
            );
          })}
      </div>
      <div key={"messageOnRisk"} className="generalDiv">
        <div
          key={"messageOnRisk"}
          className={`messageOnRisk template-messages-${colorSchema}`}
        >
          {`${data && data.messageonrisk}`}
        </div>
      </div>
      <div key={"consumoRespDesc"} className="generalDiv">
        <div
          key={"consumoRespDesc"}
          className={`consumoRespDesc template-messages-${colorSchema}`}
        >
          {data && data.messageonresponsibleconsumption}
        </div>
      </div>
      <div className={`dividerStyle`}>
        <hr className={`template-horiz-separator-${colorSchema}`} />
      </div>
    </div>
  );
};
