import { faCircleArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import "./AllegatiDaScaricareStyle.scss";
import { FC } from "react";
import { AttachmentType } from "types";
import { t } from "i18next";

interface AllegatiDaScaricareUIProps {
  attachmentsList: Array<AttachmentType>;
  sectionLabel: string;
  handleDownload: (name: string, link: string) => void;
  colorSchema: string;
}

const AllegatiDaScaricareUI: FC<AllegatiDaScaricareUIProps> = ({
  attachmentsList,
  colorSchema,
}) => {
  if (!attachmentsList || attachmentsList.length === 0) {
    return <></>;
  }

  return (
    <>
      <div>
        <div
          className={`dividerHeaderTitle template-sectiontitle-${colorSchema}`}
        >
          {t("attachments")}
        </div>
        <div className={"block"}>
          {attachmentsList &&
            attachmentsList.map((item: AttachmentType) => {
              const { name, link } = item;
              return (
                <a
                  className={"dataBlock"}
                  //onClick={() => handleDownload(name, link)}
                  download={name}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  <div
                    className={`iconStyleAllegati template-sectionsubtitle-${colorSchema}`}
                  >
                    <FontAwesomeIcon size={"1x"} icon={faCircleArrowDown} />
                  </div>
                  <div>
                    <div
                      className={`titleStyleAllegati template-sectionsubtitle-${colorSchema}`}
                    >
                      {name}
                    </div>
                  </div>
                </a>
              );
            })}
        </div>
      </div>
      <div className={`dividerStyle`}>
        <hr className={`template-horiz-separator-${colorSchema}`} />
      </div>
    </>
  );
};

export default AllegatiDaScaricareUI;
