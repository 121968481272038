export enum ColorSchemaEnum {
  default = "default",
  blue = "blue",
  red = "red",
  orange = "orange",
  white = "white",
}

export const selectedColorSchema = (colorSchema: any): ColorSchemaEnum => {
  if (Object.values(ColorSchemaEnum).includes(colorSchema as ColorSchemaEnum)) {
    return colorSchema as ColorSchemaEnum;
  } else {
    return ColorSchemaEnum.default;
  }
};
