import * as React from "react";
import { ImbottigliatoUI } from "./ImbottigliatoUI";
import { OperatorType } from "types";
interface ImbottigliatoProps {
  data: OperatorType;
  colorSchema: string;
  isImporter: boolean;
}

export const Imbottigliato = (props: ImbottigliatoProps) => {
  return (
    <ImbottigliatoUI
      data={props.data}
      colorSchema={props.colorSchema}
      isImporter={props.isImporter}
    />
  );
};
