import * as React from "react";
import RiconoscimentiEPremiUI from "./RiconoscimentiEPremiUI";
import { FC } from "react";
import { RiconoscimentiEPremiType } from "types";

interface RiconoscimentiEPremiProps {
  data: RiconoscimentiEPremiType;
  colorSchema: string;
}
const RiconoscimentiEPremi: FC<RiconoscimentiEPremiProps> = ({
  data,
  colorSchema,
}) => {
  const { sectionLabel, awardsList } = data;
  return (
    <div>
      <RiconoscimentiEPremiUI
        awards={awardsList}
        sectionLabel={sectionLabel}
        colorSchema={colorSchema}
      />
    </div>
  );
};

export default RiconoscimentiEPremi;
