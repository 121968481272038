import * as React from "react";
import "./RiconoscimentiEPremiStyle.scss";
import { AwardType } from "types";
import { FC } from "react";
import { t } from "i18next";
import "../../../ts-fonts.css";

interface RiconoscimentiEPremiUIProps {
  awards: Array<AwardType>;
  sectionLabel: string;
  colorSchema: string;
}
const RiconoscimentiEPremiUI: FC<RiconoscimentiEPremiUIProps> = ({
  awards,
  colorSchema,
}) => {
  if (!awards || awards.length === 0) {
    return <></>;
  }

  return (
    <>
      <div>
        <div
          className={`dividerHeaderTitle template-sectiontitle-${colorSchema}`}
        >
          {t("prizesAndAwards")}
        </div>
        <div className={"block"}>
          {awards &&
            awards.map((item: AwardType) => {
              const { title, description, year } = item;
              return (
                <div className={"dataBlock"}>
                  <div className={"iconStyle"}>
                    <span
                      className={`ts-icon-premi template-icon-${colorSchema}`}
                    ></span>
                  </div>
                  <div>
                    <div
                      className={`titleStyle template-sectionsubtitle-${colorSchema}`}
                    >
                      {title}
                    </div>
                    <div
                      className={`descriptionStyle template-icon-text-${colorSchema}`}
                    >
                      {year
                        ? description + " | " + (t("harvestYear") + " " + year)
                        : description}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className={`dividerStyle`}>
        <hr className={`template-horiz-separator-${colorSchema}`} />
      </div>
    </>
  );
};

export default RiconoscimentiEPremiUI;
