import * as React from "react";
import "./Ingredients.scss";
import { AllergensType } from "types";
interface AllergensUIProps {
  data: AllergensType;
}

export const AllergensUI = (props: AllergensUIProps) => {
  const { data } = props;

  return (
    <>
      {data && data.length > 0 && (
        <div key={"allergens"} className="generalDiv">
          <div key={"allergenPictograms"} className="allergensIcon">
            {data &&
              data.map((iconName: string) => {
                return (
                  <img
                    src={require(`../../../images/allergens/${iconName}.png`)}
                    alt={iconName}
                    style={{ backgroundColor: "white" }}
                  />
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};
