import * as React from "react";
import "./CustomDetails.scss";

interface CustomDetailsProps {
  title: string;
  description: string;
  icon: React.ReactNode | null;
  colorSchema: string;
}

export const CustomDetailsUI = (props: CustomDetailsProps) => {
  const { title, description, icon, colorSchema } = props;
  return (
    <div className="mainContainer">
      {icon && (
        <div className={`iconDiv template-icon-${colorSchema}`}>{icon}</div>
      )}
      <div className={icon ? "secondaryContainer" : "secondaryContainerNoIcon"}>
        <div className={`titleDiv template-icon-${colorSchema}`}>{title}</div>
        <div className={`descDiv template-icon-text-${colorSchema}`}>
          {description}
        </div>
      </div>
    </div>
  );
};
