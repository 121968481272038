import * as React from "react";
import { ProductCharacteristicsUI } from "./ProductCharacteristicsUI";
import { CharacteristicsType } from "types";
interface ProductCharacteristicsProps {
  data: CharacteristicsType;
  colorSchema: string;
}

export const ProductCharacteristics = (props: ProductCharacteristicsProps) => {
  return (
    <ProductCharacteristicsUI
      data={props.data}
      colorSchema={props.colorSchema}
    />
  );
};
