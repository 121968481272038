import * as React from "react";
import { HeaderUI } from "./HeaderUI";
import { LangElement } from "./LangElement.interface";

interface HeaderProps {
  setLanguage: (langauge: string) => void;
  languages: LangElement[];
  language: string;
  colorSchema: string;
}

export const Header = (props: HeaderProps) => {
  return (
    <HeaderUI
      languages={props.languages}
      setLanguage={props.setLanguage}
      language={props.language}
      colorSchema={props.colorSchema}
    />
  );
};
