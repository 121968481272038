import * as React from "react";
import { DegustazioneUI } from "./DegustazioneUI";
import { TastingType } from "types";

interface DegustazioneProps {
  data: TastingType;
  colorSchema: string;
}

export const Degustazione = (props: DegustazioneProps) => {
  return <DegustazioneUI data={props.data} colorSchema={props.colorSchema} />;
};
