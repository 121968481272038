import * as React from "react";
import { CustomDetailsUI } from "./CustomDetailsUI";

interface CustomDetailsProps {
  title: string;
  description: string;
  icon: React.ReactNode | null;
  colorSchema: string;
}

export const CustomDetails = (props: CustomDetailsProps) => {
  const { title, description, icon, colorSchema } = props;
  return (
    <CustomDetailsUI
      title={title}
      description={description}
      icon={icon}
      colorSchema={colorSchema}
    />
  );
};
