import * as React from "react";
import VNutrizionali from "./ValoriNutrizionali/ValoriNutrizionali";
import "./ValoriNutrizionaliStyle.scss";
import { FC } from "react";
import { JsonObj } from "types";

interface ValoriNutrizionaliMainProps {
  data: JsonObj;
  colorSchema: string;
}
const ValoriNutrizionali: FC<ValoriNutrizionaliMainProps> = ({
  data,
  colorSchema,
}) => {
  return (
    <div>
      <VNutrizionali data={data} colorSchema={colorSchema} />
    </div>
  );
};

export default ValoriNutrizionali;
