import * as React from "react";
import VaporThemeProvider from "@vapor/react-material/styles";
import VaporPage from "@vapor/react-custom/VaporPage";
import "./App.css";
// import { useTranslation } from "react-i18next";
import Main from "components/Main";
import "./AppTemplates.scss";

function App() {
  //   const { t } = useTranslation();

  const [colorSchema, setColorSchema] = React.useState<string>("default");

  return (
    <div className={`template-background-${colorSchema}`}>
      <VaporThemeProvider>
        <VaporPage.Section>
          <Main setColorSchema={setColorSchema} colorSchema={colorSchema} />
        </VaporPage.Section>
      </VaporThemeProvider>
    </div>
  );
}

export default App;
